// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_03-module--maineDigest--zgdQW";
export var heroImage = "maine_digest_2022_03-module--heroImage--jOt3J";
export var columnWrapper = "maine_digest_2022_03-module--columnWrapper--v7jOG";
export var headingLogo = "maine_digest_2022_03-module--headingLogo--aUoJH";
export var headingWrapper = "maine_digest_2022_03-module--headingWrapper--b98ih";
export var heading = "maine_digest_2022_03-module--heading--ckXM+";
export var instance = "maine_digest_2022_03-module--instance--xlBiA";
export var subheading = "maine_digest_2022_03-module--subheading--61JHw";
export var textWrapper = "maine_digest_2022_03-module--textWrapper--bFJ+t";
export var latestnewsarticleheadline = "maine_digest_2022_03-module--latestnewsarticleheadline--8pzF-";
export var dateline = "maine_digest_2022_03-module--dateline--pxhQY";
export var whatsNew = "maine_digest_2022_03-module--whatsNew--j7DI2";
export var colWrapper = "maine_digest_2022_03-module--colWrapper--FFhxU";
export var leftCol = "maine_digest_2022_03-module--leftCol--AN7oO";
export var rightCol = "maine_digest_2022_03-module--rightCol--rjCo1";
export var photoCaption = "maine_digest_2022_03-module--photoCaption--WeWUw";
export var afterCaption = "maine_digest_2022_03-module--afterCaption--XzyFd";